<template>
  <div class="size-full">
    <div class="flex min-h-[235px] items-center justify-center bg-[url('/assets/images/login_bg.jpg')] bg-center bg-no-repeat">
      <div class="flex flex-none text-white">
        <div><img src="/assets/images/login_logo.png" /></div>
        <div class="ml-2 text-[#c79c46]">
          <h2 class="text-2xl font-bold">Everything Changes</h2>
          <p><strong>AI</strong> <strong>Ri</strong>sk <strong>S</strong>ignal</p>
        </div>
      </div>
    </div>
    <slot />
    <Toast position="bottom-center" />
  </div>
</template>
